@media (max-width: 350px) {
  .col-xs-12 {
    padding: 0 5% !important;
  }
}

@media (max-width: 576px) {
  #home-section-0 .container-head {
    height: 100%;
    flex-direction: column-reverse;
    display: flex;
  }

  #home-section-0 .container-head .col-title {
    max-width: 100%;
    min-width: 100%;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  #home-section-0 .container-head .col-title h3, #home-section-0 .container-head .col-title .text-h3 {
    margin: 30px 0;
    font-size: 20px !important;
  }

  #home-section-0 .container-head .col-title .break-line {
    display: none;
  }

  #home-section-0 .container-head .col-img {
    margin-top: 10vh;
    margin-bottom: 2vh;
  }

  #home-section-1 {
    height: auto;
    margin-top: 5vh;
  }

  #home-section-1 .container .row {
    background-color: var(--dark);
    margin-top: 10vh;
  }

  #home-section-1 .container .row .wow {
    padding: 0 0 50px;
  }

  #home-section-1 .container .row .wow h4, #home-section-1 .container .row .wow .text-h4 {
    margin-top: -10px;
    font-size: 20px;
    font-weight: bold;
  }

  #home-section-1 .container .row .wow p {
    margin-top: 0;
    margin-bottom: 25px;
  }

  #home-section-1 .container .row .col-xs-12 {
    padding-bottom: 5vh;
  }

  #home-section-2 {
    height: auto;
    margin-top: -50px;
  }

  #home-section-2 .splide__slide {
    width: 90% !important;
  }

  #home-section-2 .section-content {
    box-shadow: none;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  #home-section-2 .section-content .card {
    box-shadow: none;
    padding-top: 0;
  }

  #home-section-2 .section-content .grid-products {
    grid-template-columns: repeat(2, 1fr);
  }

  #home-section-2 .section-content .grid-products .products {
    width: auto;
  }

  #home-section-3 {
    background-size: 60%;
    margin-top: 50px;
  }

  #home-section-3 .section-content {
    order: 2;
  }

  #home-section-3 .section-content h2, #home-section-3 .section-content .text-h2 {
    font-size: 35px;
  }

  #home-section-3 .image-content {
    order: 1;
    margin-top: -10vh;
  }

  #home-section-3 .image-content img {
    width: 90%;
  }

  #home-section-4 {
    background-size: 70%;
    margin-top: 0;
  }

  #home-section-4 .section-content h2, #home-section-4 .section-content .text-h2 {
    font-size: 35px;
  }

  #home-section-4 .section-content .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #home-section-4 .image-content {
    margin-top: -10vh;
  }

  #home-section-4 .image-content img {
    width: 90vw;
  }

  #subscribe {
    margin-top: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #home-section-0 .container-head {
    height: 100%;
    flex-direction: column-reverse;
    display: flex;
  }

  #home-section-0 .container-head .col-title {
    max-width: 100%;
    min-width: 100%;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  #home-section-0 .container-head .col-title h3, #home-section-0 .container-head .col-title .text-h3 {
    margin: 20px 0;
  }

  #home-section-0 .container-head .col-img {
    margin-top: 10vh;
    margin-bottom: 2vh;
  }

  #home-section-1 {
    margin-top: -100px;
    margin-bottom: 100px;
  }

  #home-section-1 .container .row .wow {
    padding: 0;
  }

  #home-section-1 .container .row .card {
    padding: 30px 20px;
  }

  #home-section-1 .container .row .card img {
    max-height: 100px;
  }

  #home-section-1 .container .row .card p {
    margin-top: 25px;
    font-size: 15px;
  }

  #home-section-3 {
    background-size: 75%;
  }

  #home-section-3 .section-content {
    order: 2;
  }

  #home-section-3 .image-content {
    order: 1;
  }

  #home-section-4 {
    background-size: 70%;
    margin-top: 100px;
  }

  #home-section-4 .image-content {
    margin-top: -50px;
    margin-bottom: 50px;
  }

  #home-section-4 .image-content img {
    width: 90vw;
  }

  #home-section-5 {
    margin-top: -50px;
  }

  #home-section-5 .section-content img {
    width: 90%;
  }

  #home-section-5 .section-content .card {
    margin-top: -80px;
    margin-left: -5px;
  }
}

@media (min-width: 992px) {
  #home-section-0 .container-head {
    margin-top: var(--navbar-height);
    height: calc(100vh - 100px);
  }

  #home-section-0 .container-head .row {
    height: 100%;
  }

  #home-section-0 .container-head h1, #home-section-0 .container-head .text-h1 {
    margin-top: -60px;
  }

  #home-section-0 .container-head h3, #home-section-0 .container-head .text-h3 {
    margin: 50px 0 !important;
  }

  #home-section-0 .container-head .head-button {
    flex-direction: row;
    display: flex;
  }

  #home-section-0 .container-head .head-button .btn {
    margin-right: 25px;
  }

  #home-section-4 {
    margin-top: -100px;
  }

  #home-section-4 .image-content img {
    height: 70vh;
  }

  #home-section-4 .section-content {
    padding-left: 100px;
  }

  #home-section-5 {
    margin-top: -50px;
  }

  #home-section-5 .section-content img {
    width: 70%;
  }

  #home-section-5 .section-content .card {
    width: 60%;
    margin-top: -180px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  #home-section-0 {
    background-position: right 100%;
    background-size: 50%;
  }

  #home-section-0 .container-head .break-line {
    display: none;
  }

  #home-section-1 .container .row .wow {
    padding: 0;
  }
}

@media (min-width: 1600px) {
  #home-section-4 .image-content .wow {
    margin-left: -100px;
  }
}

/*# sourceMappingURL=index.eb397395.css.map */
