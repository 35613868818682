
/* ==========================================================================
   Extra Small devices iPhone 5, SE
========================================================================== */
@media (max-width: 350px) {
    .col-xs-12 {
        padding: 0 5% !important;
    }
}

/* ==========================================================================
   Small devices (landscape phones, 576px and up)
========================================================================== */
@media (max-width: 576px) { 
    #home-section-0{
        .container-head {
            display: flex;
            flex-direction: column-reverse;
            height: 100%;
    
            .col-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 100%;
                min-width: 100%;

                h3, .text-h3 {
                    margin: 30px 0 30px;
                    font-size: 20px !important;
                }

                .break-line {
                    display: none;
                }
            }
    
            .col-img {
                margin-top: 10vh;
                margin-bottom: 2vh;
            }
        }
    }


    #home-section-1 {
        margin-top: 5vh;
        height: auto;

        .container {
            .row {
                margin-top: 10vh;
                background-color: var(--dark);

                .wow {
                    padding: 0;
                    padding-bottom: 50px;

                    h4, .text-h4 {
                        margin-top: -10px;
                        font-size: 20px;
                        font-weight: bold;
                    }

                    p {
                        margin-top: 0;
                        margin-bottom: 25px;
                    }
                }

                .col-xs-12 {
                    padding-bottom: 5vh;
                }
            }
        }
    }

    #home-section-2{
        margin-top: -50px;
        height: auto;

        .splide__slide {
            width: 90% !important;
        }

        .section-content {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            box-shadow: none;

            .card {
                padding-top: 0;
                box-shadow: none;
            }

            .grid-products {
                grid-template-columns: repeat(2, 1fr);
        
                .products {
                    width: auto;
                }
            }
        }
    }

    #home-section-3{
        margin-top: 50px;
        background-size: 60%;

        .section-content{
            order : 2;

            h2, .text-h2 {
                font-size: 35px;
            }
        }
        .image-content{
            order: 1;
            margin-top: -10vh;

            img {
                width: 90%;
            }
        }
    }

    #home-section-4{
        margin-top: 0;
        background-size: 70%;

        .section-content{
            h2, .text-h2 {
                font-size: 35px;
            }

            .row {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }

        .image-content{
            margin-top: -10vh;
            img {
                width: 90vw;
            }
        }
    }

    #subscribe {
        margin-top: 0;
    }
}

/* ==========================================================================
   Medium devices (tablets, 768px and up)
========================================================================== */
@media (min-width: 768px) and (max-width: 1024px) {
    #home-section-0{

        .container-head {
            display: flex;
            flex-direction: column-reverse;
            height: 100%;
    
            .col-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 100%;
                min-width: 100%;

                h3, .text-h3 {
                    margin: 20px 0;
                }
            }
    
            .col-img {
                margin-top: 10vh;
                margin-bottom: 2vh;
            }
        }
    }

    #home-section-1 {

        margin-top: -100px;
        margin-bottom: 100px;

        .container {
            .row {
                .wow {
                    padding: 0;
                }
                .card {
                    padding: 30px 20px;
                    img {
                        max-height: 100px;
                    }
    
                    p {
                        margin-top: 25px;
                        font-size: 15px;
                    }
                }
            }
        }
    }

    #home-section-3{
        background-size: 75%;
        .section-content{
            order : 2;
        }
        .image-content{
            order: 1;
        }
    }

    #home-section-4{
        margin-top: 100px;
        background-size: 70%;
        .image-content{
            margin-top: -50px;
            margin-bottom: 50px;
            img {
                width: 90vw;
            }
        }
    }

    #home-section-5 {
        margin-top: -50px;
        .section-content {
            img {
                width: 90%;
            }
            .card {
                margin-top:-80px;
                margin-left: -5px;
            }
        }
    }
}
 


/* ==========================================================================
   Large devices (desktops, 992px and up)
========================================================================== */
@media (min-width: 992px) { 
    #home-section-0{

        .container-head {
            margin-top:  var(--navbar-height);
            height: calc(100vh - 100px);

            .row { 
                height: 100%
            }
            h1, .text-h1{
                margin-top: -60px;
            }
    
            h3, .text-h3{
                margin: 50px 0 !important;
            }
    
            .head-button {
                display: flex;
                flex-direction: row;
    
                .btn {
                    margin-right: 25px;
                }
            }
        }
    }

    #home-section-4 {
        margin-top: -100px;

        .image-content {
            img {
                height: 70vh;
            }
        }
        .section-content {
            padding-left: 100px;
        }
    }

    #home-section-5 {
        margin-top: -50px;

        .section-content {
            img {
                width: 70%;
            }
            .card {
                margin-top: -180px;
                width: 60%;
            }
        }
    }
}


/* ==========================================================================
  MacBook 13 pouces
========================================================================== */
@media (min-width: 1200px) and (max-width: 1300px) { 
    #home-section-0 {
        background-size: 50%;
        background-position: right 100%;

        .container-head  .break-line {
            display: none;
        }
    }

    #home-section-1 {
        .container .row .wow {
            padding: 0;
        }
    }
}


/* ==========================================================================
   Extra extra large devices (large desktops, 1600px and up)
========================================================================== */
@media (min-width: 1600px) {
    // #home-section-1{
    //     margin-top: -10px;
    //     height: 100vh;
    // }

    #home-section-4 {
        .image-content {
            .wow {
                margin-left: -100px;
            }
        }
    }
}
